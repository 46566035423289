import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logoIcon from "../assets/img/bywallof_logo.jpg";
import "../App.css";

export default function Footer() {
  return (
    <Container fluid style={{ backgroundColor: "whitesmoke" }}>
      <Container>
        <h1
          style={{
            width: "100%",
            padding: "30px",
            background: "transparent",
            textAlign: "center",
            fontFamily: "Signika-bold",
            textDecoration: "underline",
          }}
        >
          Why hire when you can delegate?
        </h1>
      </Container>
      <Container>
        <Row style={{ paddingTop: 40, paddingBottom: 40 }}>
          <Col md={4} className="footer-box">
            <img src={logoIcon} width={80} alt="WallOf Logo" />
          </Col>
          <Col md={4} className="footer-box">
            <b>Contact</b>
            <label>
              Email:{" "}
              <a href="mailto:riaz@bywallof.co.za">riaz@bywallof.co.za</a>
            </label>
            <label>
              Phone: <a href="tel:+27837651652">083 765 1652</a>
            </label>

            <label>WhatsApp: +27 (0) 83 765 1652</label>
          </Col>
          <Col md={4} className="footer-box">
            <b>Company</b>
            trading as WallOf Digitized Innovation (Pty) Ltd. &copy; 2025
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
