import React from "react";
import "../App.css";
import { Table, Container } from "react-bootstrap";
import ContactUsModal from "../component/ContactUsPage";

export default function Pricing() {
  return (
    <Container style={{ minHeight: "90vh", marginTop: 30 }}>
      <h1>
        <strong className="n-underline-bacon">Packages</strong> & Pricing
      </h1>
      <Table bordered hover response>
        <thead>
          <tr>
            <th></th>
            <th>
              <label className="">CI/CD</label>
            </th>
            <th>Cloud</th>
            <th>Docs</th>
            <th>Code</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Basic Website</td>
            <td></td>
            <td>&#10004;</td>
            <td>&#10004;</td>
            <td>&#10004;</td>
          </tr>
          <tr>
            <td>Complex Website</td>
            <td></td>
            <td>&#10004;</td>
            <td>&#10004;</td>
            <td>&#10004;</td>
          </tr>
          <tr>
            <td>e-Commerce Website</td>
            <td>&#10004;</td>
            <td>&#10004;</td>
            <td>&#10004;</td>
            <td>&#10004;</td>
          </tr>
          <label className="subpackage">Mobile</label>
          <tr>
            <td>iOS/Android App</td>
            <td></td>
            <td>&#10004;</td>
            <td>&#10004;</td>
            <td>&#10004;</td>
          </tr>
          <tr>
            <td>Existing Software Maintenance</td>
            <td>&#10004;</td>
            <td>&#10004;</td>
            <td>&#10004;</td>
            <td>&#10004;</td>
          </tr>
          <label className="subpackage">Platform</label>
          <tr>
            <td>PaaS/SaaS</td>
            <td></td>
            <td>&#10004;</td>
            <td>&#10004;</td>
            <td>&#10004;</td>
          </tr>
          <tr>
            <td>API (Application Programming Interface)</td>
            <td>&#10004;</td>
            <td>&#10004;</td>
            <td></td>
            <td>&#10004;</td>
          </tr>
        </tbody>
      </Table>
      * will need different types of apps to make the solution most effective
      and complete.
      <br></br>
      <ContactUsModal />
    </Container>
  );
}
