import React from "react";
import { Navbar, Container } from "react-bootstrap";

export default function NavB() {
  return (
    <Navbar
      className="bg-body-tertiary"
      style={{ paddingTop: 20, paddingBottom: 20 }}
    >
      <Container>
        <Navbar.Brand href="#home">
          WallOf<b>{"/Software"}</b>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <a href="/pricing">Packages</a>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
