import React from "react";
import { Row, Col } from "react-bootstrap";
import { Container } from "react-bootstrap";
import GoLang from "../assets/img/go.png";
import { Link } from "react-router-dom";

export default function CommunicationConfirm() {
  return (
    <Container style={{ minHeight: "80vh", alignContent: "center" }}>
      <Row style={{ background: "azure" }}>
        <Col
          style={{
            margin: "auto 0",
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "center",
            alignContent: "center",
            background: "transparent",
          }}
        >
          <img src={GoLang} alt="GoLang Mascot" />
        </Col>
        <Col
          md={6}
          style={{
            textAlign: "center",
            margin: "auto 0",
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            justifyItems: "center",
            background: "transparent",
          }}
        >
          <h1>
            Cool! You should be receiving an SMS or Email confirming your
            request.
          </h1>
          <label>
            Was this written in Go, JavaScript (from TypeScript)? Explore our
            tools and services we have used to convince you.
          </label>
          <Link to={"/stack"}>View our stack</Link>
        </Col>
      </Row>
    </Container>
  );
}
